import React from 'react';
import axios from 'axios';
import SEO from '../components/SEO';

const App = () => {
  const handleBuildClick = async () => {
    try {
      const response = await axios.post('/webhook');
      console.log(response.data.message);
    } catch (error) {
      console.error('Error triggering build:', error);
    }
  };

  return (
    <>
      <SEO/>
      <button onClick={handleBuildClick}>Trigger Build</button>
    </>
  );
};

export default App;
